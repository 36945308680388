import React from 'react';
import SVGIcon, { type IconProps } from '@/Components/Icons';

// material-symbols:altitude-outline-rounded
export default function AltitudeIcon(props: IconProps) {
  return (
    <SVGIcon {...props}>
      <path
        fill="currentColor"
        d="M19 12q-.425 0-.712-.288T18 11V7.8l-.925.9q-.275.275-.675.288t-.7-.288q-.275-.275-.275-.7t.275-.7l2.6-2.6q.3-.3.7-.3t.7.3l2.6 2.6q.3.3.3.7t-.3.7t-.712.3t-.713-.3L20 7.825V11q0 .425-.287.713T19 12M3 22q-.625 0-.9-.55t.1-1.05l4-5.325q.15-.2.363-.3t.437-.1t.438.1t.362.3L11.5 20H19l-5-6.65l-1.7 2.25q-.3.4-.7.413t-.7-.213t-.4-.612t.2-.788l2.5-3.325q.15-.2.363-.3t.437-.1t.438.1t.362.3l7 9.325q.375.5.1 1.05T21 22zm8.5-2"
      />
    </SVGIcon>
  );
}
