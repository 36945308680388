import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs: ClassValue[]) {
    return twMerge(clsx(inputs));
}

export function structuredClone<T>(obj: T): T {
    // TODO: use native structuredClone when available more widely
    return JSON.parse(JSON.stringify(obj));
}

export function isPostcodeInIdf(postcode: string): boolean {
    return [75, 77, 78, 91, 92, 93, 94, 95].includes(parseInt(postcode.substring(0, 2)));
}

export const formatMoney = (
    value: number,
    roundFn?: 'floor' | 'ceil' | 'round',
): string => {
    if (roundFn) {
        value = Math[roundFn](value / 100) * 100;
    }
    return value.toLocaleString('fr', {
        style: 'currency',
        currency: 'EUR',
        maximumFractionDigits: 0,
    });
};

export const formatNumber = (
    value: number,
    roundFn?: 'floor' | 'ceil' | 'round' | true,
    roundTo: number = 1,
): string => {
    if (roundFn) {
        if (roundFn === true) {
            roundFn = 'round';
        }
        value = Math[roundFn](value / roundTo) * roundTo;
    }
    return value.toLocaleString('fr', {
        maximumFractionDigits:
            roundTo >= 1 ? 0 : parseInt(roundTo.toExponential().split('-')[1]),
    });
};

export const dpeAdemeUrl = (dpeNumber: string): string =>
    `https://observatoire-dpe-audit.ademe.fr/afficher-dpe/${dpeNumber}`;

export const lowerFirst = (str: string): string =>
    str.charAt(0).toLowerCase() + str.slice(1);

export const upperFirst = (str: string): string =>
    str.charAt(0).toUpperCase() + str.slice(1);
